import { cn } from "~/utils/cn";

const paragraphVariants = {
  "extra-extra-small": {
    text: "text-2xs",
    spacing: "mb-2.5",
  },
  "extra-small": {
    text: "text-xs",
    spacing: "mb-3 ",
  },
  small: {
    text: "text-sm",
    spacing: "mb-3.5",
  },
  base: {
    text: "sm:text-base text-sm",
    spacing: "mb-3.5",
  },
  large: {
    text: "text-base sm:text-lg",
    spacing: "mb-4 ",
  },
  "extra-large": {
    text: "text-lg sm:text-xl",
    spacing: "mb-4.5",
  },
};

export type ParagraphVariant = keyof typeof paragraphVariants;

type ParagraphProps = {
  variant?: ParagraphVariant;
  className?: string;
  children: React.ReactNode;
  textCenter?: boolean;
  capitalize?: boolean;
  spacing?: boolean;
} & React.HTMLAttributes<HTMLParagraphElement>;

export function Paragraph({
  variant = "base",
  textCenter,
  capitalize,
  className,
  spacing = false,
  children,
  ...props
}: ParagraphProps) {
  return (
    <p
      className={cn(
        "font-sans font-light text-dimmed",
        paragraphVariants[variant].text,
        spacing === true && paragraphVariants[variant].spacing,
        textCenter ? "text-center" : "",
        capitalize ? "uppercase tracking-widest" : "",
        className
      )}
      {...props}
    >
      {children}
    </p>
  );
}
